import { createEffect, createEvent, createStore, sample } from "effector";

import { ClickerState, User, UserData } from "./types.ts";

import envConfig from "../api/config.ts";
import appStorage from "../module/app-storage/index.ts";
import { StorageKeys } from "../module/app-storage/app-storage.constants.ts";
import restClient from "../api/rest-client.ts";
// import { tick } from "./state.ts";

const DEV_INIT_DATA = import.meta.env.VITE_DEV_INIT_DATA;

export const $token = createStore("");
export const $user = createStore<User | null>(null);
export const $clickerState = createStore<ClickerState | null>(null);
export const $earned = createStore<number | null>(null);
export const $isAuthLoading = createStore(false);
export const $isSyncLoading = createStore(false);
export const $listenersAdded = createStore(false);
const $isInitialized = createStore(false);
// export const $earned = createStore(0);

export const $rankUpPropsByEarh = createStore({
  show: false,
  currentRank: 0,
  canChangeState: true,
});

export const resizeRequest = createEvent();
export const resetRequestDate = createEvent();
export const callLastSyncFx = createEvent();
export const addListeners = createEvent();

sample({
  clock: addListeners,
  fn: () => true,
  target: $listenersAdded,
});

sample({
  source: {
    clickerState: $clickerState,
  },
  clock: resetRequestDate,
  fn: ({ clickerState }) => {
    if (clickerState === null) return null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { requestDate, ...rest } = clickerState;
    return rest;
  },
  target: $clickerState,
});

const setRankUpByEarn = createEvent<{
  currentRank: number;
  show: boolean;
}>();

export const resetRankUpByEarn = createEvent();

sample({
  clock: resetRankUpByEarn,
  fn: () => ({
    show: false,
    currentRank: 0,
    canChangeState: false,
  }),
  target: $rankUpPropsByEarh,
});

$rankUpPropsByEarh.on(
  setRankUpByEarn,
  (currentState, { currentRank, show }) => {
    if (!currentState.canChangeState) {
      return {
        currentRank: -1,
        show: false,
        canChangeState: false,
      };
    }
    return {
      currentRank: currentRank - 1,
      show,
      canChangeState: false,
    };
  },
);

export const $dailyBonusClaimed = $clickerState.map((clickerState) => {
  if (clickerState === null || !clickerState.dailyRewardState) return false;
  const dailyClaimed =
    clickerState?.dailyRewardState.filter((day) => day.isClaimed) || [];
  if (dailyClaimed.length === clickerState?.dailyRewardState.length) {
    return true;
  }
  const index = clickerState?.dailyRewardState.findIndex(
    (day) => !day.isAvailable && !dailyClaimed.includes(day),
  );
  return index && index !== -1
    ? clickerState?.dailyRewardState[index - 1].isClaimed
    : false;
});
export const setEarn = createEvent<number>();
export const resetEarn = createEvent();

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const authFx = createEffect<void, UserData>(async () => {
  const initData =
    import.meta.env.MODE === "development"
      ? DEV_INIT_DATA
      : window.Telegram.WebApp.initData;

  const response = await fetch(`${envConfig.apiUrl}/auth/account-info`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: initData,
    }),
  });

  const data = await response.json();
  restClient.setToken(data.token);
  return data;
});

export const handleBeacon = async () => {
  // debugger;
  // fetch(`${envConfig.apiUrl}/clicker/beacon`, {
  //   keepalive: true,
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
  restClient.post("clicker/beacon", {}, { keepalive: true });
};

interface SyncFxResponse {
  clickerState: ClickerState;
  splineUrl: string;
}

interface ExtendedSyncFxResponse extends SyncFxResponse {
  earnedData: {
    earned: number;
    requestsSinceBeacon: number;
    beaconTimestamp: number;
  };
}

export const lastSyncFx = createEffect<string, ExtendedSyncFxResponse>(
  async () => {
    const requestDate = new Date();
    // const response = await fetch(
    //   `${envConfig.apiUrl}/clicker/earned-since-last-beacon`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    // );

    const { splineUrl, clickerState, earnedData }: ExtendedSyncFxResponse =
      await restClient.post("clicker/earned-since-last-beacon");

    return {
      splineUrl,
      earnedData,
      clickerState: {
        ...clickerState,
        requestDate,
      },
    };
  },
);

export const syncFx = createEffect<string, SyncFxResponse>(async () => {
  const requestDate = new Date();

  // const response = await fetch(`${envConfig.apiUrl}/clicker/sync`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
  // if (response.status === 401) {
  //   return authFx();
  // }
  const { splineUrl, clickerState }: SyncFxResponse =
    await restClient.post("clicker/sync");

  return {
    splineUrl,
    clickerState: {
      ...clickerState,
      requestDate,
    },
  };
});

sample({
  clock: authFx.pending,
  target: $isAuthLoading,
});
sample({
  clock: syncFx.pending,
  target: $isSyncLoading,
});

// sample({
//   source: {
//     token: $token,
//     authPending: authFx.pending,
//     syncPending: syncFx.pending,
//   },
//   clock: resizeRequest,
//   filter: (source) => !source.authPending && !source.syncPending,
//   fn: ({ token }) => {
//     return token;
//   },
//   target: [syncFx],
// });
sample({
  source: {
    token: $token,
    isInitialized: $isInitialized,
  },
  clock: callLastSyncFx,
  filter: ({ token, isInitialized }) => {
    console.log({
      token,
      isInitialized,
      shouldCall: !!token && isInitialized,
    });
    return !!token && isInitialized;
  },
  fn: ({ token }) => token,
  target: lastSyncFx,
});

export const callSyncFx = createEvent();

sample({
  source: $token,
  clock: callSyncFx,
  target: syncFx,
});

sample({
  clock: authFx.doneData,
  fn: ({ token }) => token,
  target: $token,
});
sample({
  clock: authFx.doneData,
  fn: ({ user }) => user,
  target: $user,
});
sample({
  clock: authFx.doneData,
  fn: ({ token }) => token,
  target: [lastSyncFx],
});

sample({
  clock: setEarn,
  fn: (value) => (value > 0 && value < 1 ? 0 : value),
  target: $earned,
});

sample({
  clock: resetEarn,
  target: $earned.reinit,
});

// sample({
//   source: $token,
//   clock: tick,
//   filter: (token) => {
//     if (!token) return false;
//     const currentDate = new Date();
//     const lastSyncData = appStorage.getItem(
//       StorageKeys.ClickerStateLastSync,
//     ) as unknown as ClickerState;

//     if (typeof lastSyncData === "object" && lastSyncData?.requestDate) {
//       const diffTime = Math.abs(
//         currentDate.getTime() - lastSyncData.requestDate.getTime(),
//       );
//       const diffMinutes = Math.ceil(diffTime / (1000 * 60));
//       if (diffMinutes > 1) {
//         return true;
//       }
//     }

//     return false;
//   },
//   target: [syncFx],
// });

authFx.doneData.watch(({ token, user }) => {
  appStorage.setItem(StorageKeys.Token, token);
  appStorage.setItem(StorageKeys.User, user);
});
syncFx.failData.watch((error) => {
  console.log("syncFx fail", error);
});

sample({
  clock: lastSyncFx.doneData,
  fn: () => true,
  target: $isInitialized,
});

lastSyncFx.doneData.watch(({ clickerState, earnedData }) => {
  const lastSync = appStorage.getItem(
    StorageKeys.ClickerStateLastSync,
  ) as unknown as ClickerState;
  // console.log({ lastSync, clickerState });
  // if (clickerState && lastSync && lastSync.balanceCoins) {
  //   clickerState.balanceCoins - lastSync.balanceCoins >= 1 &&
  //     setEarn(clickerState.balanceCoins - lastSync.balanceCoins);
  // }

  if (earnedData && earnedData.earned > 0) {
    setEarn(earnedData.earned);
  }

  if (
    lastSync &&
    clickerState.balanceCoins > lastSync.coinsRequiredForNextRank
  ) {
    setRankUpByEarn({
      show: true,
      currentRank: lastSync.rankLevel,
    });
  }

  if (clickerState) {
    appStorage.setItem(StorageKeys.ClickerStateLastSync, clickerState);
  }
});
