import { TFunction } from "i18next";

import bronze from "../assets/leagues/bronze_league.png";
import silver from "../assets/leagues/silver_league.png";
import gold from "../assets/leagues/gold_league.png";
import platinum from "../assets/leagues/platinum_league.png";
import titanium from "../assets/leagues/titanium_league.png";
import sapphire from "../assets/leagues/sapphire_league.png";
import emerald from "../assets/leagues/emerald_league.png";
import ruby from "../assets/leagues/ruby_league.png";
import diamond from "../assets/leagues/diamond_league.png";
import quantumNexus from "../assets/leagues/quantum_nexus_league.webp";

import bank_small from "../assets/buildings/bank_small.png";
import bank_locked from "../assets/buildings/bank_locked.png";
import bank_filled from "../assets/buildings/bank_filled.png";
import bank_1 from "../assets/buildings/bank_1.webp";
import bank_2 from "../assets/buildings/bank_2.webp";
import bank_3 from "../assets/buildings/bank_3.webp";
import bank_4 from "../assets/buildings/bank_4.webp";
import bank_5 from "../assets/buildings/bank_5.webp";

import binary_market_small from "../assets/buildings/binary_market_small.png";
import binary_market_locked from "../assets/buildings/binary_market_locked.png";
import binary_market_filled from "../assets/buildings/binary_market_filled.png";
import binary_market_1 from "../assets/buildings/binary_market_1.webp";
import binary_market_2 from "../assets/buildings/binary_market_2.webp";
import binary_market_3 from "../assets/buildings/binary_market_3.webp";
import binary_market_4 from "../assets/buildings/binary_market_4.webp";
import binary_market_5 from "../assets/buildings/binary_market_5.webp";

import crypto_church_small from "../assets/buildings/crypto_church_small.png";
import crypto_church_locked from "../assets/buildings/crypto_church_locked.png";
import crypto_church_filled from "../assets/buildings/crypto_church_filled.png";
import crypto_church_1 from "../assets/buildings/crypto_church_1.webp";
import crypto_church_2 from "../assets/buildings/crypto_church_2.webp";
import crypto_church_3 from "../assets/buildings/crypto_church_3.webp";
import crypto_church_4 from "../assets/buildings/crypto_church_4.webp";
import crypto_church_5 from "../assets/buildings/crypto_church_5.webp";

import crypto_farm_small from "../assets/buildings/crypto_farm_small.png";
import crypto_farm_locked from "../assets/buildings/crypto_farm_locked.png";
import crypto_farm_filled from "../assets/buildings/crypto_farm_filled.png";
import crypto_farm_1 from "../assets/buildings/crypto_farm_1.webp";
import crypto_farm_2 from "../assets/buildings/crypto_farm_2.webp";
import crypto_farm_3 from "../assets/buildings/crypto_farm_3.webp";
import crypto_farm_4 from "../assets/buildings/crypto_farm_4.webp";
import crypto_farm_5 from "../assets/buildings/crypto_farm_5.webp";

import factory_small from "../assets/buildings/factory_small.png";
import factory_locked from "../assets/buildings/factory_locked.png";
import factory_filled from "../assets/buildings/factory_filled.png";
import factory_1 from "../assets/buildings/factory_1.webp";
import factory_2 from "../assets/buildings/factory_2.webp";
import factory_3 from "../assets/buildings/factory_3.webp";
import factory_4 from "../assets/buildings/factory_4.webp";
import factory_5 from "../assets/buildings/factory_5.webp";

import town_hall_small from "../assets/buildings/town_hall_small.png";
import town_hall_locked from "../assets/buildings/town_hall_locked.png";
import town_hall_filled from "../assets/buildings/town_hall_filled.png";
import town_hall_1 from "../assets/buildings/town_hall_1.webp";
import town_hall_2 from "../assets/buildings/town_hall_2.webp";
import town_hall_3 from "../assets/buildings/town_hall_3.webp";
import town_hall_4 from "../assets/buildings/town_hall_4.webp";
import town_hall_5 from "../assets/buildings/town_hall_5.webp";

import trading_center_small from "../assets/buildings/trading_center_small.png";
import trading_center_locked from "../assets/buildings/trading_center_locked.png";
import trading_center_filled from "../assets/buildings/trading_center_filled.png";
import trading_center_1 from "../assets/buildings/trading_center_1.webp";
import trading_center_2 from "../assets/buildings/trading_center_2.webp";
import trading_center_3 from "../assets/buildings/trading_center_3.webp";
import trading_center_4 from "../assets/buildings/trading_center_4.webp";
import trading_center_5 from "../assets/buildings/trading_center_5.webp";

import it_training_center_small from "../assets/buildings/it_training_center_small.png";
import it_training_center_locked from "../assets/buildings/it_training_center_locked.png";
import it_training_center_filled from "../assets/buildings/it_training_center_filled.png";
import it_training_center_1 from "../assets/buildings/it_training_center_1.webp";
import it_training_center_2 from "../assets/buildings/it_training_center_2.webp";
import it_training_center_3 from "../assets/buildings/it_training_center_3.webp";
import it_training_center_4 from "../assets/buildings/it_training_center_4.webp";
import it_training_center_5 from "../assets/buildings/it_training_center_5.webp";

import digital_mall_small from "../assets/buildings/digital_mall_small.png";
import digital_mall_locked from "../assets/buildings/digital_mall_locked.png";
import digital_mall_filled from "../assets/buildings/digital_mall_filled.png";
import digital_mall_1 from "../assets/buildings/digital_mall_1.webp";
import digital_mall_2 from "../assets/buildings/digital_mall_2.webp";
import digital_mall_3 from "../assets/buildings/digital_mall_3.webp";
import digital_mall_4 from "../assets/buildings/digital_mall_4.webp";
import digital_mall_5 from "../assets/buildings/digital_mall_5.webp";

import hackerspace_small from "../assets/buildings/hackerspace_small.png";
import hackerspace_locked from "../assets/buildings/hackerspace_locked.png";
import hackerspace_filled from "../assets/buildings/hackerspace_filled.png";
import hackerspace_1 from "../assets/buildings/hackerspace_1.webp";
import hackerspace_2 from "../assets/buildings/hackerspace_2.webp";
import hackerspace_3 from "../assets/buildings/hackerspace_3.webp";
import hackerspace_4 from "../assets/buildings/hackerspace_4.webp";
import hackerspace_5 from "../assets/buildings/hackerspace_5.webp";

import robo_advising_center_small from "../assets/buildings/robo_advising_center_small.png";
import robo_advising_center_locked from "../assets/buildings/robo_advising_center_locked.png";
import robo_advising_center_filled from "../assets/buildings/robo_advising_center_filled.png";
import robo_advising_center_1 from "../assets/buildings/robo_advising_center_1.webp";
import robo_advising_center_2 from "../assets/buildings/robo_advising_center_2.webp";
import robo_advising_center_3 from "../assets/buildings/robo_advising_center_3.webp";
import robo_advising_center_4 from "../assets/buildings/robo_advising_center_4.webp";
import robo_advising_center_5 from "../assets/buildings/robo_advising_center_5.webp";

import wellness_center_small from "../assets/buildings/wellness_center_small.png";
import wellness_center_locked from "../assets/buildings/wellness_center_locked.png";
import wellness_center_filled from "../assets/buildings/wellness_center_filled.png";
import wellness_center_1 from "../assets/buildings/wellness_center_1.webp";
import wellness_center_2 from "../assets/buildings/wellness_center_2.webp";
import wellness_center_3 from "../assets/buildings/wellness_center_3.webp";
import wellness_center_4 from "../assets/buildings/wellness_center_4.webp";
import wellness_center_5 from "../assets/buildings/wellness_center_5.webp";

import tgIconSmall from "../assets/social/tg_icon_small.png";
import tgIconBig from "../assets/social/tg_icon_big.png";
import xIconSmall from "../assets/social/x_icon_small.png";
import xIconBig from "../assets/social/x_icon_big.png";
import youtubeIconSmall from "../assets/social/youtube_icon_small.png";
import youtubeIconBig from "../assets/social/youtube_icon_big.png";
import instagramIconSmall from "../assets/social/instagram_icon_small.png";
import instagramIconBig from "../assets/social/instagram_icon_big.png";
import fifteenFriendsIconSmall from "../assets/social/fifteen_friends_icon_small.png";
import fifteenFriendsIconBig from "../assets/social/fifteen_friends_icon_big.png";
import happyThreeFriendsIconSmall from "../assets/social/happy_three_friends_icon_small.png";
import happyThreeFriendsIconBig from "../assets/social/happy_three_friends_icon_big.png";
import facebookIcon from "../assets/social/fb.png";
import twitchIcon from "../assets/social/twitch.png";
import openUrlIcon from "../assets/social/link.png";

import zehphyrImg from "../assets/characters/Zephyr.png";
import tiko83Img from "../assets/characters/Tiko83.png";
import hopperImg from "../assets/characters/Hopper.png";
import hopperClicker from "../assets/characters/hopper_clicker.png";
import zephyrClicker from "../assets/characters/zephyr_clicker.png";
import tiko83Clicker from "../assets/characters/tiko83_clicker.png";
import zephyrIcon from "../assets/characters/zephyr_small.png";
import hopperIcon from "../assets/characters/hopper_small.png";
import tiko83Icon from "../assets/characters/tiko83_small.png";

import bearMarketSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/bear_market_small.webp";
import blockchainConferenceSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/blockchain_conference_small.webp";
import bullMarketSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/bull_market_small.webp";
import cyberDefenseDrillSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/cyber_drill_small.webp";
import hackerAttackSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/hacker_attack_small.webp";
import newCryptocurrencyLaunchSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/new_crypto_launch_small.webp";
import technologicalBreakthroughSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/techbreakthrough_small.webp";
import bearMarketHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/bear_market_hidden.webp";
import blockchainConferenceHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/blockchain_conference_hidden.webp";
import bullMarketHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/bull_market_hidden.webp";
import cyberDefenseDrillHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/cyber_drill_hidden.webp";
import hackerAttackHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/hacker_attack_hidden.webp";
import newCryptocurrencyLaunchHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/new_crypto_launch_hidden.webp";
import technologicalBreakthroughHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/techbreakthrough_hidden.webp";

export const LEAGUES = [
  {
    name: "Bronze",
  },
  {
    name: "Silver",
  },
  {
    name: "Gold",
  },
  {
    name: "Platinum",
  },
  {
    name: "Titanium",
  },
  {
    name: "Sapphire",
  },
  {
    name: "Emerald",
  },
  {
    name: "Ruby",
  },
  {
    name: "Diamond",
  },
  {
    name: "Quantum Nexus",
  },
];

export const leaguesMap: { [key: string]: string } = {
  Bronze: bronze,
  Silver: silver,
  Gold: gold,
  Platinum: platinum,
  Titanium: titanium,
  Sapphire: sapphire,
  Emerald: emerald,
  Ruby: ruby,
  Diamond: diamond,
  QuantumNexus: quantumNexus,
};

export const buildingsSmallMap: { [key: string]: string } = {
  bank: bank_small,
  binary_market: binary_market_small,
  crypto_church: crypto_church_small,
  crypto_farm: crypto_farm_small,
  factory: factory_small,
  it_training_center: it_training_center_small,
  town_hall: town_hall_small,
  trading_center: trading_center_small,
  digital_mall: digital_mall_small,
  hackerspace: hackerspace_small,
  robo_advising_center: robo_advising_center_small,
  wellness_center: wellness_center_small,
};

export const buildingsLockedMap: { [key: string]: string } = {
  bank: bank_locked,
  binary_market: binary_market_locked,
  crypto_church: crypto_church_locked,
  crypto_farm: crypto_farm_locked,
  factory: factory_locked,
  it_training_center: it_training_center_locked,
  town_hall: town_hall_locked,
  trading_center: trading_center_locked,
  digital_mall: digital_mall_locked,
  hackerspace: hackerspace_locked,
  robo_advising_center: robo_advising_center_locked,
  wellness_center: wellness_center_locked,
};

export const buildingsFilledMap: { [key: string]: string } = {
  bank: bank_filled,
  binary_market: binary_market_filled,
  crypto_church: crypto_church_filled,
  crypto_farm: crypto_farm_filled,
  factory: factory_filled,
  it_training_center: it_training_center_filled,
  town_hall: town_hall_filled,
  trading_center: trading_center_filled,
  digital_mall: digital_mall_filled,
  hackerspace: hackerspace_filled,
  robo_advising_center: robo_advising_center_filled,
  wellness_center: wellness_center_filled,
};

export const buildingsNamesMap: { [key: string]: string } = {
  bank: "Bank",
  binary_market: "Binary Market",
  crypto_church: "Crypto Church",
  crypto_farm: "Crypto Farm",
  factory: "Factory",
  it_training_center: "It Training Center",
  town_hall: "Town Hall",
  trading_center: "Trading Center",
  invite_friends: "Invite friends",
  digital_mall: "Digital Mall",
  hackerspace: "Hackerspace",
  robo_advising_center: "Robo-advising center",
  wellness_center: "Wellness Center",
};

export const buildingsCoversMap: { [key: string]: string } = {
  bank_1: bank_1,
  bank_2: bank_2,
  bank_3: bank_3,
  bank_4: bank_4,
  bank_5: bank_5,
  binary_market_1: binary_market_1,
  binary_market_2: binary_market_2,
  binary_market_3: binary_market_3,
  binary_market_4: binary_market_4,
  binary_market_5: binary_market_5,
  crypto_church_1: crypto_church_1,
  crypto_church_2: crypto_church_2,
  crypto_church_3: crypto_church_3,
  crypto_church_4: crypto_church_4,
  crypto_church_5: crypto_church_5,
  crypto_farm_1: crypto_farm_1,
  crypto_farm_2: crypto_farm_2,
  crypto_farm_3: crypto_farm_3,
  crypto_farm_4: crypto_farm_4,
  crypto_farm_5: crypto_farm_5,
  factory_1: factory_1,
  factory_2: factory_2,
  factory_3: factory_3,
  factory_4: factory_4,
  factory_5: factory_5,
  it_training_center_1: it_training_center_1,
  it_training_center_2: it_training_center_2,
  it_training_center_3: it_training_center_3,
  it_training_center_4: it_training_center_4,
  it_training_center_5: it_training_center_5,
  town_hall_1: town_hall_1,
  town_hall_2: town_hall_2,
  town_hall_3: town_hall_3,
  town_hall_4: town_hall_4,
  town_hall_5: town_hall_5,
  trading_center_1: trading_center_1,
  trading_center_2: trading_center_2,
  trading_center_3: trading_center_3,
  trading_center_4: trading_center_4,
  trading_center_5: trading_center_5,
  digital_mall_1: digital_mall_1,
  digital_mall_2: digital_mall_2,
  digital_mall_3: digital_mall_3,
  digital_mall_4: digital_mall_4,
  digital_mall_5: digital_mall_5,
  hackerspace_1: hackerspace_1,
  hackerspace_2: hackerspace_2,
  hackerspace_3: hackerspace_3,
  hackerspace_4: hackerspace_4,
  hackerspace_5: hackerspace_5,
  robo_advising_center_1: robo_advising_center_1,
  robo_advising_center_2: robo_advising_center_2,
  robo_advising_center_3: robo_advising_center_3,
  robo_advising_center_4: robo_advising_center_4,
  robo_advising_center_5: robo_advising_center_5,
  wellness_center_1: wellness_center_1,
  wellness_center_2: wellness_center_2,
  wellness_center_3: wellness_center_3,
  wellness_center_4: wellness_center_4,
  wellness_center_5: wellness_center_5,
};

export const ACCENT_TYPE = ["positive", "negative", "neutral"] as const;

export const socialIconsSmallMap: { [key: string]: string } = {
  subscribe_telegram: tgIconSmall,
  join_group_telegram: tgIconSmall,
  subscribe_twitter: xIconSmall,
  subscribe_youtube: youtubeIconSmall,
  subscribe_twitch: twitchIcon,
  subscribe_instagram: instagramIconSmall,
  subscribe_facebook: facebookIcon,
  invite_friends_3: happyThreeFriendsIconSmall,
  invite_friends_15: fifteenFriendsIconSmall,
  invite_friends_30: fifteenFriendsIconSmall,
  open_url: openUrlIcon,
};

export const socialIconsBigMap: { [key: string]: string } = {
  subscribe_telegram: tgIconBig,
  join_group_telegram: tgIconBig,
  subscribe_twitter: xIconBig,
  subscribe_youtube: youtubeIconBig,
  subscribe_twitch: twitchIcon,
  subscribe_instagram: instagramIconBig,
  subscribe_facebook: facebookIcon,
  invite_friends_3: happyThreeFriendsIconBig,
  invite_friends_15: fifteenFriendsIconBig,
  invite_friends_30: fifteenFriendsIconBig,
  open_url: openUrlIcon,
};

export const socialTextMap = (t: TFunction): { [key: string]: string } => {
  return {
    subscribe_telegram: t("subscribe_telegram"),
    join_group_telegram: t("join_group_telegram"),
    subscribe_twitter: t("subscribe_twitter"),
    subscribe_youtube: t("subscribe_youtube"),
    subscribe_twitch: t("subscribe_twitch"),
    subscribe_facebook: t("subscribe_facebook"),
    subscribe_instagram: t("subscribe_instagram"),
    invite_friends_3: t("invite_friends_3"),
    invite_friends_15: t("invite_friends_15"),
    invite_friends_30: t("invite_friends_30"),
    open_url: t("open_link"),
  };
};

export const socialRewardConditionTextMap = (
  t: TFunction,
): { [key: string]: string } => {
  return {
    subscribe_telegram: t("subscribe_telegram"),
    join_group_telegram: t("join_group_telegram"),
    subscribe_twitter: t("subscribe_twitter"),
    subscribe_youtube: t("subscribe_youtube"),
    subscribe_twitch: t("subscribe_twitch"),
    subscribe_facebook: t("subscribe_facebook"),
    subscribe_instagram: t("subscribe_instagram"),
    invite_friends_3: t("invite_friends_3"),
    invite_friends_15: t("invite_friends_15"),
    invite_friends_30: t("invite_friends_30"),
    open_url: t("open_link"),
  };
};

export const cityTaskNamingTextMap = (
  t: TFunction,
): { [key: string]: string } => {
  return {
    bank: t("cityTask.title.bank"),
    binary_market: t("cityTask.title.binary_market"),
    crypto_church: t("cityTask.title.crypto_church"),
    town_hall: t("cityTask.title.town_hall"),
    factory: t("cityTask.title.factory"),
    it_training_center: t("cityTask.title.it_training_center"),
    crypto_farm: t("cityTask.title.crypto_farm"),
    trading_center: t("cityTask.title.trading_center"),
    digital_mall: t("cityTask.title.digital_mall"),
    hackerspace: t("cityTask.title.hackerspace"),
    robo_advising_center: t("cityTask.title.robo_advising_center"),
    wellness_center: t("cityTask.title.wellness_center"),
  };
};

export const TURBO_BOOST_TIMER = 20000;
export const TAP_SEND_TIMER = 30000;

export const charactersMap: Record<
  string,
  {
    bigImg: string;
    smallImg: string;
    clickerImg: string;
    color: string;
    name: string;
    role: string;
  }
> = {
  zephyr: {
    name: "Zephyr",
    role: "chain runner",
    bigImg: zehphyrImg,
    smallImg: zephyrIcon,
    clickerImg: zephyrClicker,
    color: "#E3AE54",
  },
  hopper: {
    name: "Hopper",
    role: "whales crusher",
    bigImg: hopperImg,
    smallImg: hopperIcon,
    clickerImg: hopperClicker,
    color: "#4C8FC5",
  },
  tiko83: {
    name: "Tiko83",
    role: "TON-digger",
    bigImg: tiko83Img,
    smallImg: tiko83Icon,
    clickerImg: tiko83Clicker,
    color: "#E36653",
  },
};

export const getEventsMap = (
  t: TFunction,
): Record<
  string,
  {
    title: string;
    description: string;
    smallCover: string;
    hiddenCover: string;
  }
> => ({
  bull_market: {
    title: t("Bull Market"),
    description: t("Cryptocurrency prices surge"),
    smallCover: bullMarketSmall,
    hiddenCover: bullMarketHidden,
  },
  bear_market: {
    title: t("Bear Market"),
    description: t("Falling cryptocurrency prices"),
    smallCover: bearMarketSmall,
    hiddenCover: bearMarketHidden,
  },
  technological_breakthrough: {
    title: t("Technological Breakthrough"),
    description: t("Introduction of new blockchain technology"),
    smallCover: technologicalBreakthroughSmall,
    hiddenCover: technologicalBreakthroughHidden,
  },
  blockchain_conference: {
    title: t("Blockchain Conference"),
    description: t(
      "An international blockchain conference is happening in the city",
    ),
    smallCover: blockchainConferenceSmall,
    hiddenCover: blockchainConferenceHidden,
  },
  hacker_attack: {
    title: t("Hacker Attack"),
    description: t("A break-in from one of the city's stock exchanges"),
    smallCover: hackerAttackSmall,
    hiddenCover: hackerAttackHidden,
  },
  new_cryptocurrency_launch: {
    title: t("New Cryptocurrency Launch"),
    description: t("Launch of a promising new cryptocurrency"),
    smallCover: newCryptocurrencyLaunchSmall,
    hiddenCover: newCryptocurrencyLaunchHidden,
  },
  cyber_defense_drill: {
    title: t("Cyber Defense Drill"),
    description: t(
      "The city is conducting drills to defend against crypto-attacks",
    ),
    smallCover: cyberDefenseDrillSmall,
    hiddenCover: cyberDefenseDrillHidden,
  },
});

export const LOCALSTORAGE_KEYS_TO_KEEP = ["token"];
