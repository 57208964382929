import {
  botApiVersions,
  InvoiceStatus,
  TelegramApiFeatures,
  TelegramApiFeaturesVersionsMap,
} from "./telegram-api.constants";

export class TelegramApiController {
  private telegramVersion: string;
  public platfrom: string;

  constructor() {
    this.platfrom = window.Telegram.WebApp.platform;
    this.telegramVersion = window.Telegram.WebApp.version;
  }

  public isFeatureSupportByCurrentVersion = (feature: TelegramApiFeatures) => {
    const featureVersion = TelegramApiFeaturesVersionsMap[feature];
    const indexOfFeatureVersion = botApiVersions.indexOf(featureVersion);
    const indexOfTelegramVersion = botApiVersions.indexOf(this.telegramVersion);
    return indexOfTelegramVersion <= indexOfFeatureVersion;
  };

  public disableVerticalSwipe = () => {
    if (
      !this.isFeatureSupportByCurrentVersion(
        TelegramApiFeatures.VericalSwipeControl,
      )
    ) {
      return;
    }
    window.Telegram.WebApp.disableVerticalSwipes();
  };

  public enableVerticalSwipe = () => {
    if (
      !this.isFeatureSupportByCurrentVersion(
        TelegramApiFeatures.VericalSwipeControl,
      )
    ) {
      return;
    }
    window.Telegram.WebApp.enableVerticalSwipes();
  };

  public openInvoice = async (
    url: string,
    callback: (status: InvoiceStatus) => void,
  ) => {
    if (
      !this.isFeatureSupportByCurrentVersion(TelegramApiFeatures.OpenInvoice)
    ) {
      return;
    }
    await window.Telegram.WebApp.openInvoice(url, (status) =>
      callback(status as InvoiceStatus),
    );
  };

  get isInvoiceSupported() {
    return this.isFeatureSupportByCurrentVersion(
      TelegramApiFeatures.OpenInvoice,
    );
  }
}
