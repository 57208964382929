import { useTranslation } from "react-i18next";
import backgroundImageDefault from "../../assets/background-default.webp";
import comingSoonIcons from "../../assets/icons/coming_soon_icons.png";
import errorImage from "./assets/error_page.webp";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div
      className="relative flex h-screen max-h-screen w-full flex-col items-center justify-between overflow-hidden bg-cover bg-center text-center"
      style={{ backgroundImage: `url(${backgroundImageDefault})` }}
    >
      <div
        className="
          scrollbar-hide relative flex h-screen min-h-full w-full 
          flex-col overflow-auto scroll-smooth 
          bg-cover bg-center bg-no-repeat p-4 py-12
        "
      >
        <h1 className="mb-8 text-3xl font-bold text-white">
          {t("ErrorPage_Title")}
        </h1>

        <img
          src={errorImage}
          width="349"
          height="181"
          alt="Error page image"
          className="slide-in-fwd-center mx-auto h-auto max-w-full shrink-0 will-change-[opacity,_transform]"
        />

        <p className="mt-5 px-4 text-center text-lg font-bold text-white">
          {t("ErrorPage_description")}
        </p>

        <div className="mt-10 flex flex-col items-center justify-center gap-3 px-4">
          <img
            src={comingSoonIcons}
            alt="Coming soon icons"
            width="98"
            height="26"
          />

          <h1 className="text-lg font-semibold text-[#9BDD7C]">
            {t("ErrorPage_restartText")}
          </h1>
        </div>
      </div>
    </div>
  );
};
