import { createEvent, createStore } from "effector";

import appStorage from "../module/app-storage";
import { StorageKeys } from "../module/app-storage/app-storage.constants";

export const API_URL = "http://localhost:3000";
export const GAME_SPEED = 1000;
export const TAP_ENERGY_COST = 1;
export const ALLOWED_LANGUAGES = ["en", "ru", "de", "fr", "es"] as const;
export const MAX_ENERGY_RESTORATION_PER_DAY = 6;
export const MAX_HOURS_TO_GET_PROFIT_PER_HOUR = 1 / 30; // TODO: 4
export const TIMER_DELAY = 2200;
export const $isPageLoaded = createStore(false);

export const setLanguage = createEvent<string>();
export const pageLoaded = createEvent();
export const tick = createEvent();
export const syncData = createEvent();

$isPageLoaded.on(pageLoaded, () => true);

let intervalId: ReturnType<typeof setInterval>;
pageLoaded.watch(() => {
  window.Telegram.WebApp.setHeaderColor("#000000");
  if (intervalId !== null) {
    clearInterval(intervalId);
  }
  intervalId = setInterval(tick, GAME_SPEED);
});

tick.watch(() => {
  appStorage.setItem(StorageKeys.LastTickData, new Date().toISOString());
});
