import { RouteObject } from "react-router-dom";

type Loader = Promise<() => JSX.Element | null>;

const loaders: Record<string, Loader> = {};

function createLoader(path: string, loader: () => Loader) {
  return async () => {
    console.log(`Lazy loading ${path}`);

    if (!loaders[path]) {
      console.log(`Initialize loader for ${path}`);
      loaders[path] = loader();
    } else {
      console.log(`Using existed loader for ${path}`);
    }

    return { Component: await loaders[path] };
  };
}

export const routes: RouteObject[] = [
  {
    path: "/",
    lazy: createLoader("/", async () => {
      const { HomePage } = await import("../pages/HomePage/Page.tsx");
      return HomePage;
    }),
  },
  {
    path: "/onboarding",
    lazy: createLoader("/onboarding", async () => {
      const { OnboardingLayout } = await import(
        "../pages/Onboarding/Layout.tsx"
      );
      return OnboardingLayout;
    }),
    children: [
      {
        index: true,
        lazy: createLoader("/onboarding/index", async () => {
          const { OnboardingIndexPage } = await import(
            "../pages/Onboarding/Index/Page.tsx"
          );
          return OnboardingIndexPage;
        }),
      },
      {
        path: "step1",
        lazy: createLoader("/onboarding/step1", async () => {
          const { OnboardingStep1Page } = await import(
            "../pages/Onboarding/Step1/Page.tsx"
          );
          return OnboardingStep1Page;
        }),
      },
      {
        path: "step2",
        lazy: createLoader("/onboarding/step2", async () => {
          const { OnboardingStep2Page } = await import(
            "../pages/Onboarding/Step2/Page.tsx"
          );
          return OnboardingStep2Page;
        }),
      },
      {
        path: "step3",
        lazy: createLoader("/onboarding/step3", async () => {
          const { OnboardingStep3Page } = await import(
            "../pages/Onboarding/Step3/Page.tsx"
          );
          return OnboardingStep3Page;
        }),
      },
      {
        path: "step4",
        lazy: createLoader("/onboarding/step4", async () => {
          const { OnboardingStep4Page } = await import(
            "../pages/Onboarding/Step4/Page.tsx"
          );
          return OnboardingStep4Page;
        }),
      },
      {
        path: "step5",
        lazy: createLoader("/onboarding/step5", async () => {
          const { OnboardingStep5Page } = await import(
            "../pages/Onboarding/Step5/Page.tsx"
          );
          return OnboardingStep5Page;
        }),
      },
      {
        path: "step6",
        lazy: createLoader("/onboarding/step6", async () => {
          const { OnboardingStep6Page } = await import(
            "../pages/Onboarding/Step6/Page.tsx"
          );
          return OnboardingStep6Page;
        }),
      },
      {
        path: "step7",
        lazy: createLoader("/onboarding/step7", async () => {
          const { OnboardingStep7Page } = await import(
            "../pages/Onboarding/Step7/Page.tsx"
          );
          return OnboardingStep7Page;
        }),
      },
    ],
  },
  {
    path: "/game",
    lazy: createLoader("/game", async () => {
      const { GameLayout } = await import("../pages/GamePage/Layout.tsx");
      return GameLayout;
    }),
    children: [
      {
        index: true,
        lazy: createLoader("/game/index", async () => {
          const { GameClickerPage } = await import(
            "../pages/GamePage/ClickerPage/Page.tsx"
          );
          return GameClickerPage;
        }),
      },
      {
        path: "city-builder",
        lazy: createLoader("/game/city-builder", async () => {
          const { GameCityBuilderPage } = await import(
            "../pages/GamePage/CityBuilderPage/Page.tsx"
          );
          return GameCityBuilderPage;
        }),
      },
      {
        path: "moves",
        lazy: createLoader("/game/moves", async () => {
          const { MovesPage } = await import(
            "../pages/GamePage/MovesPage/Page.tsx"
          );
          return MovesPage;
        }),
      },
      {
        path: "moves/single",
        lazy: createLoader("/game/moves/single", async () => {
          const { SingleMove } = await import(
            "../pages/GamePage/MovesPage/subpages/SingleMovePage.tsx"
          );
          return SingleMove;
        }),
      },
      {
        path: "moves/my-moves",
        lazy: createLoader("/game/moves/my-moves", async () => {
          const { MyMovesPage } = await import(
            "../pages/GamePage/MovesPage/subpages/MyMovesPage.tsx"
          );
          return MyMovesPage;
        }),
      },
      {
        path: "champs",
        lazy: createLoader("/game/champs", async () => {
          const { ChampsPage } = await import(
            "../pages/GamePage/ChampsPage/Page.tsx"
          );
          return ChampsPage;
        }),
      },
      {
        path: "earn",
        lazy: createLoader("/game/earn", async () => {
          const { EarnPage } = await import(
            "../pages/GamePage/EarnPage/Page.tsx"
          );
          return EarnPage;
        }),
      },
      {
        path: "airdrop",
        lazy: createLoader("/game/airdrop", async () => {
          const { AirdropPage } = await import(
            "../pages/GamePage/AirdropPage/Page.tsx"
          );
          return AirdropPage;
        }),
      },
      {
        path: "settings",
        lazy: createLoader("/game/settings", async () => {
          const { SettingsPage } = await import(
            "../pages/GamePage/SettingsPage/Page.tsx"
          );
          return SettingsPage;
        }),
      },
      {
        path: "settings/language",
        lazy: createLoader("/game/settings/language", async () => {
          const { LanguagePage } = await import(
            "../pages/GamePage/SettingsPage/LanguagePage/Page.tsx"
          );
          return LanguagePage;
        }),
      },
      {
        path: "boosters",
        lazy: createLoader("/game/boosters", async () => {
          const { BoosterScreen } = await import(
            "../pages/GamePage/BoostsPage/Page.tsx"
          );
          return BoosterScreen;
        }),
      },
      {
        path: "skins",
        lazy: createLoader("/game/skins", async () => {
          const { SkinsPage } = await import(
            "../pages/GamePage/SkinsPage/Page.tsx"
          );
          return SkinsPage;
        }),
      },
    ],
  },
  {
    path: "*",
    lazy: createLoader("*", async () => {
      const { NotFound } = await import("../components/errors/NotFound.tsx");
      return NotFound;
    }),
  },
];
