import { combine, createEvent, createStore, sample } from "effector";
import { debounce } from "patronum";
import { TAP_ENERGY_COST, TIMER_DELAY } from "./state.ts";

import {
  $clickerState,
  $earned,
  $token,
  resetRequestDate,
  syncFx,
} from "./auth.ts";
import { startTapTimerFx, tapFx, turboTapFx } from "./clicker.ts";
import {
  $boosters,
  $boosterTimeLeft,
  $isTurboBoostEnabled,
  $turboBoostCoef,
  buyBoost,
  buyBoostFx,
  calculateCoefFx,
  finishTurboTimer,
  // startTimerFx,
  startTurboTimer,
} from "./boosts.ts";
import { $isNextLeaguePopupOpened, showNextLeaguePopup } from "./league.ts";
import { buyCityBuilderUpgradeFx, buyUpgradeProperty } from "./citybuilder.ts";
import { ClickerState, TurboTapPayload } from "./types.ts";
import { initOnboard } from "./onboard.ts";
import appStorage from "../module/app-storage/index.ts";
import { StorageKeys } from "../module/app-storage/app-storage.constants.ts";

// /Events
export const coinsTap = createEvent();
initOnboard();
// \Events

// /Stores

export const $localTapCount = createStore(0);
export const $energy = createStore(0);
export const $maxEnergy = createStore(0);
export const $energyRecoverPerTick = createStore(0);
export const $earnPassivePerHour = createStore(0);
export const $balanceCoins = createStore(0);
export const $rankLevel = createStore(0);
export const $allowToShowReward = createStore(true);
export const $firstClickRankUpBalance = createStore(0);
export const $firstClickStartRank = createStore(0);

export const allowToShowShowRewardEvent = createEvent<boolean>();
export const setFirstClickRankUpkBalance = createEvent<number>();
export const setFirstClickStartRank = createEvent<number>();
export const dropFirstClickData = createEvent();

sample({
  clock: dropFirstClickData,
  target: [$firstClickStartRank.reinit, $firstClickRankUpBalance.reinit],
});

sample({
  clock: setFirstClickRankUpkBalance,
  target: $firstClickRankUpBalance,
});

sample({
  clock: setFirstClickStartRank,
  target: $firstClickStartRank,
});

export const $totalCoins = combine(
  $clickerState,
  $balanceCoins,
  $localTapCount,
  $turboBoostCoef,
  $earned,
  (clickerState, balanceCoins, tapCount, turboBoostCoef, earned) => {
    const balanceWithEarnCorrect =
      earned && earned > 0 ? balanceCoins - earned : balanceCoins;

    if (clickerState === null) return 0;
    const { coinsPerTap } = clickerState;
    if (turboBoostCoef === null) {
      return balanceWithEarnCorrect + tapCount * coinsPerTap;
    }
    return balanceWithEarnCorrect + tapCount * coinsPerTap * turboBoostCoef;
  },
);
export const $isLeagueChanged = combine(
  $firstClickRankUpBalance,
  $totalCoins,
  $allowToShowReward,
  $isNextLeaguePopupOpened,
  (
    firstClickRankUpBalance,
    totalCoins,
    allowToShowReward,
    isNextLeaguePopupOpened,
  ) => {
    if (
      firstClickRankUpBalance === 0 ||
      !allowToShowReward ||
      isNextLeaguePopupOpened
    )
      return false;
    return totalCoins >= firstClickRankUpBalance;
  },
);

export const $isFirstTapWithoutTimer = combine(
  $localTapCount,
  $isTurboBoostEnabled,
  startTapTimerFx.pending,
  (tapCount, isTurboBoostEnabled, isPending) => {
    // запускаем таймер после первого тапа только если турбо-буст не активирован и нет уже запущенного таймера
    if (isPending === false && tapCount === 0 && isTurboBoostEnabled === false)
      return true;
    return false;
  },
);

export const $$profitPerTick = $earnPassivePerHour.map((value) => {
  return value / 3600;
});
// \Stores

$allowToShowReward.on(allowToShowShowRewardEvent, (_, payload) => {
  return payload;
});

export const dropRequestDate = createEvent();

$energy
  .on($clickerState.updates, (state, clickerState) => {
    if (clickerState === null) return state;
    if (!clickerState.requestDate) return state;
    if (clickerState.maxEnergy === clickerState.availableEnergy)
      return clickerState.availableEnergy;
    if (clickerState.requestDate) {
      if (clickerState.availableEnergy < state) {
        const currentDate = new Date();
        const timeDiffBetweenEvents =
          currentDate.getTime() - clickerState.requestDate.getTime();
        const energyPerTick = clickerState.energyRecoverPerSec;
        const addedSinceEventEnergy =
          (timeDiffBetweenEvents / 1000) * energyPerTick;
        return clickerState.availableEnergy + addedSinceEventEnergy;
      }
    }
    return clickerState.availableEnergy;
  })
  .on(coinsTap, (state) => {
    if (state - TAP_ENERGY_COST <= 0) {
      return 0;
    }
    return state - TAP_ENERGY_COST;
  })
  .watch(() => {
    resetRequestDate();
  });
$maxEnergy.on($clickerState.updates, (state, clickerState) => {
  if (clickerState === null) return state;
  return clickerState.maxEnergy;
});
$energyRecoverPerTick.on($clickerState.updates, (state, clickerState) => {
  if (clickerState === null) return state;
  return clickerState.energyRecoverPerSec;
});
$earnPassivePerHour.on($clickerState.updates, (state, clickerState) => {
  if (clickerState === null) return state;
  return clickerState.earnPassivePerHour;
});
$balanceCoins.on($clickerState.updates, (state, clickerState) => {
  if (clickerState === null) return state;
  return clickerState.balanceCoins;
});
// .on(buyUpgradeProperty, (state, { upgrade_price }) => {
//   console.log({ state, upgrade_price });
//   return state - upgrade_price;
// });
$rankLevel.on($clickerState.updates, (state, clickerState) => {
  if (clickerState === null) return state;
  return clickerState.rankLevel;
});
//сброс тапов после их отправки на сервак
$localTapCount.reset([tapFx.done, turboTapFx.done]);

//вычисляем коэф турбобуста после его включения
//и должны делать это только один раз если нет уже коэфа и функцию калькулейта не в запуске
sample({
  source: {
    isTurboBoostEnabled: $isTurboBoostEnabled,
    turboBoostCoef: $turboBoostCoef,
    calculationStarted: calculateCoefFx.pending,
  },
  clock: coinsTap,
  filter: ({ isTurboBoostEnabled, turboBoostCoef, calculationStarted }) =>
    isTurboBoostEnabled && !calculationStarted && turboBoostCoef === null,
  target: calculateCoefFx,
});

//запускаем таймер после включения турбобуста только если он был включен и не запущен
sample({
  source: {
    isTurboBoostEnabled: $isTurboBoostEnabled,
    turboBoostCoef: $turboBoostCoef,
    boosterTimeLeft: $boosterTimeLeft,
    // timerStared: startTimerFx.pending,
  },
  clock: coinsTap,
  filter: ({ isTurboBoostEnabled, turboBoostCoef, boosterTimeLeft }) =>
    isTurboBoostEnabled && boosterTimeLeft === 0 && turboBoostCoef !== null,
  // fn: ({ isTurboBoostEnabled, turboBoostCoef }) =>
  //   ({ turboBoostCoef, isTurboBoostEnabled }) as {
  //     turboBoostCoef: number;
  //     isTurboBoostEnabled: boolean;
  //   },
  target: startTurboTimer,
});

//старт тап таймера
sample({
  source: {
    energy: $energy,
    tapCount: $localTapCount,
  },
  clock: coinsTap,
  filter: $isFirstTapWithoutTimer,
  target: startTapTimerFx,
});
//увеличение тапов
sample({
  source: {
    energy: $energy,
    tapCount: $localTapCount,
  },
  clock: coinsTap,
  filter: ({ energy }) => energy > 0,
  fn: ({ tapCount }) => {
    return tapCount + TAP_ENERGY_COST;
  },
  target: $localTapCount,
});
//файрим ивент если при локальных тапах перешли на след лигу
sample({
  source: {
    totalCoins: $totalCoins,
    clickerState: $clickerState,
    isNextLeaguePopupOpened: $isNextLeaguePopupOpened,
  },
  clock: [coinsTap, $allowToShowReward],
  filter: $isLeagueChanged,
  // fn: ({ totalCoins, clickerState, isNextLeaguePopupOpened }) => {
  //   if (clickerState === null || isNextLeaguePopupOpened) return false;

  //   const { coinsRequiredForNextRank } = clickerState;
  //   console.log(
  //     {
  //       totalCoins,
  //       coinsRequiredForNextRank: clickerState.coinsRequiredForNextRank,
  //     },
  //     "<--- triggered here",
  //     totalCoins >= coinsRequiredForNextRank,
  //   );
  //   if (totalCoins >= coinsRequiredForNextRank) {
  //     return true;
  //   }
  //   return false;
  // },
  fn: () => {
    console.log("showNextLeaguePopup, game domain");
  },
  target: [showNextLeaguePopup],
});

//берем токен и после открытия попапа о некст уровне синхронизируемся
sample({
  source: $token,
  clock: showNextLeaguePopup,
  target: syncFx,
});

//отправка тапов на сервак 2 сек спустя после последнего тапа или 30 секунд спустя после первого тапа
const event = debounce(coinsTap, TIMER_DELAY);
sample({
  source: {
    currentEnergy: $energy,
    tapCount: $localTapCount,
    token: $token,
    turboBoostCoef: $turboBoostCoef,
  },
  clock: [event, startTapTimerFx.done],
  filter: ({ turboBoostCoef, tapCount }) =>
    turboBoostCoef === null && tapCount !== 0,
  target: tapFx,
});
//также отправка тапов если протапали макс энергию
sample({
  source: {
    currentEnergy: $energy,
    maxEnergy: $maxEnergy,
    tapCount: $localTapCount,
    token: $token,
    turboBoostCoef: $turboBoostCoef,
  },
  clock: coinsTap,
  filter: ({ tapCount, maxEnergy, turboBoostCoef }) =>
    turboBoostCoef === null && tapCount === maxEnergy,
  target: tapFx,
});

//отправка тапов после окончания турбо-буста
sample({
  source: {
    currentEnergy: $energy,
    tapCount: $localTapCount,
    token: $token,
    clickerState: $clickerState,
    // isTurboBoostEnabled: $isTurboBoostEnabled,
    turboBoostCoef: $turboBoostCoef,
    isRequestActive: turboTapFx.pending,
  },
  clock: finishTurboTimer,
  filter: ({ isRequestActive, tapCount }) => {
    return !isRequestActive && tapCount !== 0;
  },
  fn: ({ currentEnergy, turboBoostCoef, tapCount, token, clickerState }) =>
    ({
      currentEnergy,
      tapCount,
      token,
      boostMultiplier: turboBoostCoef,
      coinsPerTap: clickerState ? clickerState.coinsPerTap : 1,
      isFinished: true,
    }) as TurboTapPayload,
  target: turboTapFx,
});
//также отправка турботапов если протапали макс энергию
sample({
  source: {
    currentEnergy: $energy,
    maxEnergy: $maxEnergy,
    tapCount: $localTapCount,
    token: $token,
    clickerState: $clickerState,
    isTurboBoostEnabled: $isTurboBoostEnabled,
    turboBoostCoef: $turboBoostCoef,
    isRequestActive: turboTapFx.pending,
  },
  clock: coinsTap,
  filter: ({ isRequestActive, tapCount, maxEnergy, isTurboBoostEnabled }) =>
    !isRequestActive && isTurboBoostEnabled && tapCount === maxEnergy,
  fn: ({ currentEnergy, tapCount, token, turboBoostCoef, clickerState }) =>
    ({
      currentEnergy,
      tapCount,
      token,
      boostMultiplier: turboBoostCoef,
      coinsPerTap: clickerState ? clickerState.coinsPerTap : 1,
    }) as TurboTapPayload,
  target: [turboTapFx, finishTurboTimer],
});

//optimistic updates
sample({
  source: {
    boosters: $boosters,
    balanceCoins: $balanceCoins,
  },
  clock: buyBoost,
  filter: buyBoostFx.pending,
  fn: ({ boosters, balanceCoins }, boostId) => {
    const boost = boosters.find((boost) => boost.id === boostId);
    if (boost === undefined) return balanceCoins;
    return balanceCoins - boost.price;
  },
  target: $balanceCoins,
});
sample({
  source: {
    balanceCoins: $balanceCoins,
  },
  clock: buyUpgradeProperty,
  filter: buyCityBuilderUpgradeFx.pending,
  fn: ({ balanceCoins }, { upgrade_price }) => {
    return balanceCoins - upgrade_price;
  },
  target: $balanceCoins,
});

turboTapFx.watch((params) => {
  console.log("turboTapFx", params);
});
turboTapFx.finally.watch(({ params }) => {
  console.log("turboTapFx", params);
});

sample({
  source: {
    clickerState: $clickerState,
  },
  clock: $balanceCoins.updates,
  filter: ({ clickerState }, balanceCoins) => {
    if (!clickerState) return false;
    return balanceCoins - clickerState.balanceCoins >= 1;
  },
  fn: ({ clickerState }, balanceCoins) => {
    return { ...clickerState, balanceCoins } as ClickerState;
  },
  target: $clickerState,
}).watch((newState) => {
  if (newState) appStorage.setItem(StorageKeys.ClickerStateLastSync, newState);
});
